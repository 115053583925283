@import url("https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@300;400;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Londrina Solid", cursive;
  font-weight: 300;
}
